import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import('./components/Login.vue')
const Home = () => import('./components/Home.vue')
const Welcome = () => import('./components/Welcome.vue')
const Imgs = () => import('./components/Configure/imgs.vue')
const System = () => import('./components/Configure/system.vue')
const Personal = () => import('./components/Account/personal.vue')
const All = () => import('./components/Account/all.vue')
const Introduction = () => import('./components/Introduction/introduction.vue')
const Connect = () => import('./components/Introduction/connect.vue')
const Product = () => import('./components/Product/product.vue')
const Time = () => import('./components/Product/time.vue')
const CodeList = () => import('./components/Code/codeList.vue')
const Warehousing = () => import('./components/Warehouse/warehousing.vue')
const Out = () => import('./components/Warehouse/out.vue')
const Map = () => import('./components/Warehouse/map.vue')
const Location = () => import('./components/Warehouse/location.vue')
const Inventory = () => import('./components/Warehouse/inventory.vue')
const Client = () => import('./components/Client_1/client.vue')
const Opinion = () => import('./components/Opinion/opinion.vue')
const ScanCode = () => import('./components/ScanCode/scanCode.vue')







Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home', component: Home, redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/imgs', component: Imgs},
      { path: '/system', component: System},
      { path: '/personal', component: Personal },
      { path: '/all', component: All },
      { path: '/introduction', component: Introduction },
      { path: '/connect', component: Connect},
      { path: '/product', component: Product},
      { path: '/time', component: Time},
      { path: '/codeList', component: CodeList},
      { path: '/warehousing', component: Warehousing },
      { path: '/out', component: Out},
      { path: '/location', component: Location},
      { path: '/inventory', component: Inventory},
      { path: '/client', component: Client},
      { path: '/opinion', component: Opinion},
      { path: '/scanCode', component: ScanCode},
      { path: '/map', component: Map}
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {

  if (to.path == '/login') {
    window.sessionStorage.removeItem('menuList')
    window.sessionStorage.removeItem('authType')
    window.sessionStorage.removeItem('data')
    window.localStorage.removeItem('token')
    return next()
}
    
// if(to.path == '/welcome') {
//     window.sessionStorage.removeItem('activePath')
// } 
const tokenStr = window.localStorage.getItem('jd_token')
if (!tokenStr) return next('/login')
next()
})

export default router
