import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

// 导入字体图标
import './assets/font/iconfont.css'
// 导入全局样式表
import './assets/css/global.css'

// 导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// 导入富文本编辑器对应点样式
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(VueQuillEditor)

import axios from 'axios'
let url = 'https://myhdfcode.mingdingkeji.com/'
// let url = 'https://hdfcode.mingdingkeji.com/'

// axios.defaults.baseURL = '/api/'
axios.defaults.baseURL = url
//添加请求拦截器
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  config.headers.token = window.localStorage.getItem('jd_token')
  return config
})

// // 上传文件/图片
// Vue.prototype.$URL = '/api/'
Vue.prototype.$URL = url

// 显示图片
Vue.prototype.$URL_img = url
Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
